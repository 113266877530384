<template>
  <div>
    <el-popover placement="bottom" title="列表控制" :width="200" trigger="click">
      <template #reference>
        <el-icon><Tools /></el-icon>

      </template>
      <div>
        <!-- <p>显示/隐藏</p> -->
        <el-checkbox v-model="checkedAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全部</el-checkbox>
        <div v-for="(item,index) in labels" :key="index">
          <el-checkbox v-model="item.checked">{{ item.label }}</el-checkbox>
        </div>

      </div>
    </el-popover>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";

export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const checkedAll = ref(false);
    const isIndeterminate = ref(false);

    const checkList = ref([]);

    const handleCheckAllChange = (val) => {
      let labels = props.labels.map((i) => ({
        label: i.label,
        checked: val,
      }));
      emit("update:labels", labels);
      setIndeterminate();
    };

    watch(
      () => props.labels,
      () => {
        setIndeterminate();
      },
      {
        deep: true,
      }
    );
    const setIndeterminate = () => {
      let selectCount = props.labels.filter((i) => i.checked);
      isIndeterminate.value =
        selectCount.length > 0 && selectCount.length < props.labels.length;
      if (selectCount.length == props.labels.length) {
        checkedAll.value = true;
      } else {
        checkedAll.value = false;
      }
    };
    onMounted(() => {
      setIndeterminate();
    });
    return { checkList, checkedAll, isIndeterminate, handleCheckAllChange };
  },
};
</script>

<style lang="scss" scoped>
</style>