<template>
  <div>
    <div class="menu_wrap">
      <div class="title">
        德拉姆供应商平台
      </div>
      <div class="menu">
        <Menu></Menu>
      </div>
      <SelectSuppPopover v-if="userInfo.userType == 'STAFF'"></SelectSuppPopover>
      <MassagePopover></MassagePopover>
      <p>{{userInfo.name}}</p>
      <el-dropdown @command="handleCommand">
        <div class="info_box">
          <img v-if="userInfo.avatar" class="avatar" :src="userInfo.avatar" alt="">
          <p v-else class="iconfont top_bar_icon">&#xe6df;</p>
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="1"><el-icon>
                <EditPen />
              </el-icon>修改密码</el-dropdown-item>
            <el-dropdown-item command="2"><el-icon>
                <Close />
              </el-icon>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- <el-popover placement="top-start" title="Title" :width="200" trigger="hover" content="this is content, this is content, this is content">
        <template #reference>
          <div class="info_box">
            <p>厦门普美霖卫浴科技有限公司</p>
            <p class="iconfont top_bar_icon">&#xe6df;</p>
          </div>
        </template>
      </el-popover> -->

    </div>
    <TagsRouter></TagsRouter>
    <ModifyPasswordDialog v-model="showModifyPasswordDialog"></ModifyPasswordDialog>
  </div>
</template>

<script>

import SelectSuppPopover from "@/components/SelectSuppPopover";
import MassagePopover from "@/components/MassagePopover";
import ModifyPasswordDialog from "@/components/ModifyPasswordDialog";
import TagsRouter from "@/components/TagsRouter";
import Menu from "@/components/Menu";
import { closeWebSocket } from "@/utils/socket";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { logout } from "@/api/login/login";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const showModifyPasswordDialog = ref(false);
    const userInfo = computed(() => store.state.userInfo);

    const handleCommand = (command) => {
      if (command == 1) {
        showModifyPasswordDialog.value = true;
      } else if (command == 2) {
        logout().then((res) => {});
        store.commit("clearTagsRouter");
        store.commit("menuList", []);
        store.commit("paths", []);
        store.commit("routerFinished", 0);
        closeWebSocket();
        router.replace({
          path: "/login",
        });
        store.commit("initTagsRouter");
      }
    };

    return {
      showModifyPasswordDialog,
      handleCommand,
      userInfo,
    };
  },

  components: {
    Menu,
    TagsRouter,
    ModifyPasswordDialog,
    MassagePopover,
    SelectSuppPopover,
  },
};
</script>

<style lang="scss" scoped>
.menu_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: #545c64;
  background: linear-gradient(135deg, #05f 0%, #013fbb 100%);
  color: #fff;
  line-height: 48px;
  height: 48px;
  font-size: 14px;
  .title {
    margin-left: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  .menu {
    flex: 1;
  }
  .info_box {
    display: flex;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    margin-right: 15px;
    .top_bar_icon {
      margin: 10px;
      font-size: 22px;
    }
  }
  .avatar{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    object-fit: cover;
    padding: 0 10px 0px 9px;
    display: block;
  }
}
</style>