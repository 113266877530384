<template>
  <div style="padding-left:10px">
    <el-menu
    :default-active="activeIndex"
    class="menu"
    mode="horizontal"
    background-color="transparent"
    text-color="#fff"
    active-text-color="#fff"
    @select="handleSelect"
  >
    <el-sub-menu :index="menu.id" v-for="(menu) in  menuList" :key="menu.index">
      <template #title>{{menu.meta.title}}</template>
      <el-menu-item style="color:#4c4c4c"  :index="subMenu.id" v-for="(subMenu) in  menu.children" :key="subMenu.index" @click="onMenuItem(subMenu)">{{subMenu.meta.title}}</el-menu-item>
    </el-sub-menu>
    <!-- <el-sub-menu index="1">
      <template #title>采购单据</template>
      <el-menu-item index="1-1" @click="onMenuItem">采购合同</el-menu-item>
    </el-sub-menu> -->
  </el-menu>
  </div>
</template>

<script>
import {reactive, ref ,computed, onActivated} from "vue";
import tagsRouter from "@/utils/tagsRouter";
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    const menuList = computed(() => store.state.menuList);
    const activeIndex = ref("1-1");
    // const menuList = reactive([
    //   {
    //     index: "1",
    //     title: "采购单据",
    //     children: [
    //       {
    //         index: "1-1",
    //         title: "采购合同",
    //         path: "/purchaseContract",
    //       },
    //       {
    //         index: "1-2",
    //         title: "送货单",
    //         path: "/deliveryOrder",
    //       },
    //     ],
    //   },
    //   // {
    //   //   index: "2",
    //   //   title: "送货单",
    //   //   children: [
    //   //     {
    //   //       index: "2-1",
    //   //       title: "送货单",
    //   //       path: "/deliveryOrder",
    //   //     },
    //   //   ],
    //   // },

    // ]);
    const handleSelect = (key, keyPath) => {
      // console.log(key, keyPath);
    };
    const onMenuItem = (menu) => {
      tagsRouter(menu.meta.title, menu.path);
      // tagsRouter("采购合同", "/purchaseContract");
    };
  
    return {
      activeIndex,
      menuList,
      handleSelect,
      onMenuItem
    };
  },
};
</script>
<style lang="scss">
  .el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title{
    // color: #ffffff !important;
    // border-bottom: none !important;
    border-bottom-color: transparent !important;
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
    color: #004adf !important;
    background-color: transparent !important;
  }
  .el-menu--horizontal.el-menu{
    border-bottom: none !important;
  }
</style>
<style lang="scss" scoped>
.menu{
  height: 48px;
  border-bottom:none
}
</style>