
export function filesToBase64(files) {
  if (files.length == 0) {
    return []
  }

  let promises = files.map(file => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        resolve(e.target.result);
      };
    });
  })

  return Promise.all(promises)
}



export function  mateDict(value,list){
  if(list == undefined || list.length == 0){
    return ''
  }
  let result = list.find(item => item.dictValue == value);
  if(result == undefined){
    return ''
  }
  return result.dictLabel

}