import { createApp } from 'vue'
import  '@/assets/css/reset.css'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import './assets/css/element/index.scss'
import DlmTable from "@/components/DlmTable"
import DlmTableColumn from "@/components/DlmTableColumn"
import AdvancedSearch from "@/components/AdvancedSearch"
import pagination from "@/components/pagination"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import tagsRouter from '@/utils/tagsRouter'
import {mateDict} from '@/utils/util/util'
import '@/utils/socket/index.js'

const app = createApp(App)

app.use(ElementPlus, {
  locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$tagsRouter = tagsRouter
app.config.globalProperties.$mateDict = mateDict

app.component('pagination',pagination)
app.component('AdvancedSearch',AdvancedSearch)
app.component('DlmTable',DlmTable)
app.component('DlmTableColumn',DlmTableColumn)
app.use(store).use(router).mount('#app')
