<template>
  <div>
    <AdvancedSearch @search="search" @reset="reset">
      <el-form label-width="100px" inline>
        <el-form-item label="合同编号">
          <el-input v-model="params.billNo" clearable placeholder="合同编号" class="search_w"></el-input>
        </el-form-item>
        <el-form-item label="推送时间">
          <el-date-picker v-model="times" clearable type="daterange" value-format="YYYY-MM-DD" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="SKU">
          <el-input v-model="params.sku" clearable placeholder="SKU" class="search_w"></el-input>
        </el-form-item>
        <el-form-item label="SKU名称">
          <el-input v-model="params.skuName" clearable placeholder="SKU名称" class="search_w"></el-input>
        </el-form-item>
      </el-form>
    </AdvancedSearch>
    <!-- <ToggleTableColumn v-model:labels="labels"></ToggleTableColumn> -->
    <div ref="tableBoxRef">
      <dlm-table :data="tableData" style="width: 100%" :height="tableHeight" border v-model:labels="labels" @selection-change="handleSelectionChange" stripe>
        <el-table-column type="selection" width="55"  align="center"/>
        <el-table-column type="expand">
          <template #default="props">
            <div style="margin-left: 103px;">
              <el-table :data="props.row.details" border stripe scrollbar-always-on>
                <el-table-column prop="poBillNo" label="采购订单" min-width="180" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sku" label="SKU" min-width="180" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="skuName" label="SKU名称" min-width="260" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="qty" label="采购数量" min-width="180" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="fnskuLabelRequire" label="FNSKU贴标要求" min-width="180" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="estArrivalTime" label="预计到货日期" min-width="180" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remark" label="说明" min-width="180" align="center" show-overflow-tooltip></el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <dlm-table-column prop="billNo" label="合同编号" min-width="180" align="center" show-overflow-tooltip>
          <template #default="scope">
            <el-link :underline="false" type="primary" @click="onBillNo(scope.row,scope.$index)">{{scope.row.billNo}}</el-link>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="pushTime" label="推送时间" min-width="180" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="purCompany" label="采购主体" min-width="320" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="settleType" label="结算方式" min-width="180" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="purchaseUserName" label="采购员" min-width="180" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="currencyType" label="币别" min-width="120" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="isIncludTax" label="税别" min-width="120" align="center" show-overflow-tooltip></dlm-table-column>
      </dlm-table>
    </div>
    <pagination :total="total" v-model:pageNum="params.pageNum" v-model:pageSize="params.pageSize" @change="getList"></pagination>
  </div>
</template>

<script>
import useTableHeight from "@/use/useTableHeight";
import tagsRouter from "@/utils/tagsRouter";
import ToggleTableColumn from "@/components/ToggleTableColumn";
import { onActivated, onMounted, reactive, ref } from "vue";
import { getListCght } from "@/api/cght";
export default {
  name:"purchaseContract",
  setup() {
    const { tableBoxRef, tableHeight } = useTableHeight();
    const total = ref(0);

    const times = ref([]);
    const selectData = ref([]);
    let reqParams = {};
    const params = reactive({
      billNo: "",
      endTime: "",
      isAsc: "",
      orderByColumn: "",
      sku: "",
      skuName: "",
      startTime: "",
      pageNum: 1,
      pageSize: 20,
    });
    const labels = ref([]);
    const tableData = ref([]);
    const handleSelectionChange = (val) => {
      selectData.value = val;
    };

    const onBillNo = (row) => {
      tagsRouter(row.billNo, "/purchaseContract/purchaseContractAdd", {
        billNo: row.billNo,
      });
    };

    const getList = () => {
      reqParams.pageNum = params.pageNum;
      reqParams.pageSize = params.pageSize;
      getListCght(reqParams).then((res) => {
        if (res.resCode == 0) {
          let { rows = [] } = res.data;
          tableData.value = rows;
          total.value =  parseInt(res.data.total);
        }
      });
    };

    const search = () => {
      let  myTimes = times.value || [];
      params.startTime = myTimes[0] ? myTimes[0] + " 00:00:00" : "";
      params.endTime = myTimes[1] ? myTimes[1] + " 23:59:59" : "";

      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          reqParams[key] = params[key];
        }
      }
      getList();
    };

    const reset = () => {
      params.billNo = "";
      params.endTime = "";
      params.isAsc = "";
      params.orderByColumn = "";
      params.sku = "";
      params.skuName = "";
      params.startTime = "";
      params.pageNum = 1;
      times.value = [];
      reqParams = {};
      getList();
    };
    onActivated(() => {
      getList();
    });
    return {
      reset,
      search,
      times,
      tableBoxRef,
      tableHeight,
      total,
      tableData,
      labels,
      params,
      handleSelectionChange,
      onBillNo,
      getList,
    };
  },
  components: {
    ToggleTableColumn,
  },
};
</script>

<style lang="scss" scoped>
</style>