import axios, {post,get} from '@/axios'


export const getListCght = (params)=>{
  return post("/suppcooperate/cght/list",params)
}

export const getDetailCght = (params)=>{
  return get("/suppcooperate/cght/getSuppCooperateDetail",params)
}


export const delContract = (params)=>{
  return get("/suppcooperate/cght/delContract",params)
}

