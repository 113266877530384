<template>
  <div>
    <AdvancedSearch @advancedSearch="setHeight" @search="search" @reset="reset">
      <el-form label-width="100px" inline>
        <el-form-item label="送货单">
          <el-input v-model="params.billNo" @keyup.enter.native="search" clearable placeholder="请输入检索内容" class="search_w"></el-input>
        </el-form-item>
        <el-form-item label="采购订单">
          <el-input v-model="params.poBillNo" @keyup.enter.native="search" clearable placeholder="请输入检索内容" class="search_w"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <!-- style="width:100%" -->
          <el-select v-model="params.status" class="search_w" placeholder="全部" >
            <el-option v-for="item in statusList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
          </el-select>
        </el-form-item>
        <el-form-item label="提货日期">
          <el-date-picker v-model="times" clearable type="daterange" value-format="YYYY-MM-DD" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>

      </el-form>
      <template #bottom>
        <el-form label-width="100px" inline>
          <el-form-item label="SKU">
            <el-input v-model="params.sku" clearable @keyup.enter.native="search" placeholder="请输入检索内容" class="search_w"></el-input>
          </el-form-item>
          <el-form-item label="SKU名称">
            <el-input v-model="params.skuName" clearable @keyup.enter.native="search" placeholder="请输入检索内容" class="search_w"></el-input>
          </el-form-item>
          <el-form-item label="推送时间">
            <el-date-picker v-model="pushTimes" clearable type="daterange" value-format="YYYY-MM-DD" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
          </el-form-item>
        </el-form>
      </template>
    </AdvancedSearch>
    <!-- <ToggleTableColumn v-model:labels="labels"></ToggleTableColumn> -->
    <div class="btn_box">
      <el-button type="primary" :disabled="selectData.length == 0" :loading="mergePrintLoading" @click="onMergePrint">合并打印</el-button>
    </div>
    <div ref="tableBoxRef" v-loading="loading">
      <dlm-table ref="tableRef" :data="tableData" style="width: 100%" :height="tableHeight" border v-model:labels="labels" @selection-change="handleSelectionChange" stripe row-key="id">
        <!-- <el-table-column type="index" label="序号" width="55" align="center" /> -->
        <el-table-column type="selection" :selectable="(row)=>row.isParent == 1 && row.status=='NORMAL'" label="序号" width="55" align="center" reserve-selection fixed="left"></el-table-column>

        <dlm-table-column prop="billNo" label="送货单" min-width="150" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div class="cell el-tooltip p0" v-if="scope.row.isParent == 1">{{scope.row.billNo}}</div>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="sendTime" label="推送时间" min-width="160" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div class="cell el-tooltip p0" v-if="scope.row.isParent == 1">{{scope.row.sendTime}}</div>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="purCompany" label="收货公司" min-width="220" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div class="cell el-tooltip p0" v-if="scope.row.isParent == 1">
              {{ $mateDict(scope.row.purCompany,purchaseCompanyList)}}
            </div>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="address" label="地址/联系人" min-width="220" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div class="cell el-tooltip p0" v-if="scope.row.isParent == 1">{{scope.row.address}}</div>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="purchaser" label="采购员" min-width="80" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div class="cell el-tooltip p0" v-if="scope.row.isParent == 1">{{scope.row.purchaser}}</div>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="poBillNo" label="采购订单" min-width="160" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div class="cell el-tooltip p0" v-if="scope.row.isParent == 1">{{scope.row.poBillNo}}</div>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="sku" label="SKU/SKU名称" min-width="220" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>
              <div class="cell el-tooltip p0">{{scope.row.sku}}</div>
              <div class="cell el-tooltip p0">{{scope.row.skuName}}</div>
            </div>
          </template>

        </dlm-table-column>
        <dlm-table-column prop="fnskuLabelRequire" label="FNSKU贴标要求" min-width="180" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="pickGoodsQty" label="数量" min-width="80" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="unit" label="单位" min-width="80" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="cases" label="箱数" min-width="80" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="pgBillNo" label="采购提货单" min-width="160" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="pickType" label="提货类型" min-width="100" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>
              {{ $mateDict(scope.row.pickType,pickTypeList)}}
            </div>
          </template>
        </dlm-table-column>
        <dlm-table-column prop="pickGoodsTime" label="提货日期" min-width="120" align="center" show-overflow-tooltip></dlm-table-column>
        <dlm-table-column prop="status" label="状态" min-width="80" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>
              {{ $mateDict(scope.row.status,statusList)}}
            </div>
          </template>

        </dlm-table-column>
        <dlm-table-column prop="id" label="操作" min-width="80" align="center" show-overflow-tooltip fixed="right">
          <template #default="scope">
            <el-link v-if="scope.row.isParent == 1 && scope.row.status=='NORMAL'" :underline="false" type="primary" @click="onPrint(scope.row,scope.$index)">打印</el-link>
          </template>
        </dlm-table-column>
      </dlm-table>
    </div>
    <pagination :total="total" v-model:pageNum="params.pageNum" v-model:pageSize="params.pageSize" @change="getList" @sizeChange="sizeChange"></pagination>

    <iframeDialog :src="pdfSrc" v-model="showPrintDialog"></iframeDialog>
  </div>
</template>

<script>
import iframeDialog from "@/components/iframeDialog";
import useTableHeight from "@/use/useTableHeight";
import tagsRouter from "@/utils/tagsRouter";
import ToggleTableColumn from "@/components/ToggleTableColumn";
import { onActivated, onMounted, reactive, ref } from "vue";
import { getListDeliveryOrder, printTemplate } from "@/api/deliveryOrder";
import { getDict } from "@/api/base";

export default {
  name: "deliveryOrder",
  setup() {
    const { tableBoxRef, tableHeight, setHeight } = useTableHeight();
    const tableRef = ref(null);
    const total = ref(0);
    const showPrintDialog = ref(false);
    const mergePrintLoading = ref(false);
    const loading = ref(false);
    
    const pdfSrc = ref("");
    const times = ref([]);
    const pushTimes = ref([]);
    const statusList = ref([]);
    const pickTypeList = ref([]);
    const purchaseCompanyList = ref([]);

    const selectData = ref([]);
    let reqParams = {};
    const params = reactive({
      billNo: "",
      isAsc: "",
      orderByColumn: "",
      pickGoodsEndTime: "",
      pickGoodsStartTime: "",
      poBillNo: "",
      sendEndTime: "",
      sendStartTime: "",
      sku: "",
      skuName: "",
      status: "NORMAL",
      pageNum: 1,
      pageSize: 20,
    });
    const labels = ref([]);
    const tableData = ref([]);
    const handleSelectionChange = (val) => {
      selectData.value = val;
    };

    // const onBillNo = (row) => {
    //   tagsRouter(row.billNo, "/purchaseContract/purchaseContractAdd", {
    //     billNo: row.billNo,
    //   });
    // };
    const getPrintTemplate = (ids) => {
      printTemplate(ids).then((res) => {
        if (res.blob) {
          pdfSrc.value = res.blob;
          showPrintDialog.value = true;
        }
        mergePrintLoading.value = false;
      }).catch((err) => {
        mergePrintLoading.value = false;
      });
    };
    
    const onMergePrint = (row) => {
      mergePrintLoading.value = true;
      let ids = selectData.value.map((item) => {
        return item.shId;
      });
      getPrintTemplate(ids);
    };
    const onPrint = (row) => {
      getPrintTemplate([row.shId]);
    };

    const getList = () => {
      reqParams.pageNum = params.pageNum;
      reqParams.pageSize = params.pageSize;
      reqParams.status = params.status;
      loading.value = true;
      getListDeliveryOrder(reqParams).then((res) => {
        if (res.resCode == 0) {
          let { rows = [] } = res.data;
          if (!rows) {
            rows = [];
          }
          if (rows && rows.length > 0) {
            rows.forEach((item, index) => {
              item._index = item.id + "_" + index;
            });
          }

          tableData.value = rows;
          total.value = parseInt(res.data.total);
        }
        loading.value = false;
      }).catch((err) => {
        loading.value = false;
      });
    };

    const getDictList = () => {
      getDict({
        codeList: ["sh_status", "pick_type", "purchase_company"],
      }).then((res) => {
        if (res.resCode == 0) {
          res.data.forEach((item) => {
            if (item.dictCode == "sh_status") {
              statusList.value = item.sysDictDataVos;
            } else if (item.dictCode == "pick_type") {
              pickTypeList.value = item.sysDictDataVos;
            } else if (item.dictCode == "purchase_company") {
              purchaseCompanyList.value = item.sysDictDataVos;
            }
          });
        }
      });
    };

    const search = () => {
      let myTimes = times.value || [];
      params.pickGoodsStartTime = myTimes[0] ? myTimes[0] + " 00:00:00" : "";
      params.pickGoodsEndTime = myTimes[1] ? myTimes[1] + " 23:59:59" : "";

      let myPushTimes = pushTimes.value || [];
      params.sendStartTime = myPushTimes[0] ? myPushTimes[0] + " 00:00:00" : "";
      params.sendEndTime = myPushTimes[1] ? myPushTimes[1] + " 23:59:59" : "";

      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          reqParams[key] = params[key];
        }
      }
      tableRef.value.clearSelection();
      params.pageNum = 1;
      getList();
    };

    const reset = () => {
      params.billNo = "";
      params.isAsc = "";
      params.orderByColumn = "";
      params.pickGoodsEndTime = "";
      params.pickGoodsStartTime = "";
      params.poBillNo = "";
      params.sendEndTime = "";
      params.sendStartTime = "";
      params.sku = "";
      params.skuName = "";
      params.status = "NORMAL";

      times.value = [];
      pushTimes.value = [];
      reqParams = {};
      tableRef.value.clearSelection();
      params.pageNum = 1;
      getList();
    };
    const sizeChange = () => {
      tableRef.value.clearSelection();
    }
    onActivated(() => {
      getList();
      getDictList();
    });
    return {
      reset,
      search,
      times,
      tableBoxRef,
      tableHeight,
      total,
      tableData,
      labels,
      params,
      handleSelectionChange,
      onPrint,
      getList,
      setHeight,
      showPrintDialog,
      pushTimes,
      statusList,
      pickTypeList,
      purchaseCompanyList,
      selectData,
      pdfSrc,
      onMergePrint,
      mergePrintLoading,
      sizeChange,
      tableRef,
      loading
    };
  },
  components: {
    ToggleTableColumn,
    iframeDialog,
  },
};
</script>

<style lang="scss" scoped>
</style>