import router from '@/router/index'
import store from '@/store'

const tagsRouter = (name,path,query) => {
    store.commit("addTagsRouter",{
        name,path,query
    })
    router.push({
        path,
        query
    })
}

export default tagsRouter 