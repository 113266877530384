<template>
  <el-dialog :modelValue="modelValue" :title="title" draggable width="1200" @close="close" top="6vh">
    <div class="box" :style="[{height:height}]">
        <iframe class="iframe" v-if="src" style="height:100%;width:100%;" :src="src"></iframe>
      </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    src:{
      type:String,
      default:"/CGRK23121802768.pdf"
    },
    title:{
      type: String,
      default: "打印"
    },
    height: {
      type: String,
      default: "78vh"
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const confirm = () => {
    };
    const cancel = () => {
      close();
    };
    const close = () => {
      emit("update:modelValue", false);
    };

    return { cancel,  confirm, close };
  },
};
</script>

<style lang="scss" scoped>
</style>