import {
  getRoutersApi
} from '@/api/menu'
import store from '@/store'
import router from '@/router'
import whiteList from '@/router/whiteList'

function getPaths(list, res = []) {
  if (list.length == 0) {
    return [];
  }
  list.forEach(item => {
    res.push(item.path);
    if (item.children && item.children.length > 0) {
      getPaths(item.children, res)
    }
  })
  return [...new Set(res)];
}

function getRouterList() {
  
  getRoutersApi().then(res => {
    let {
      data
    } = res;
    if (data.length > 0 && data[0].children.length > 0) {
      let paths = getPaths(data[0].children)
      store.commit("menuList", data)
      store.commit("paths", paths)
      initRouter(paths);
    }else{
      store.commit("menuList", []);
      store.commit("paths", []);
      initRouter([]);
    }
    store.commit("routerFinished", 1)

  }).catch(err => {
    store.commit("menuList", []);
    store.commit("paths", []);
    store.commit("routerFinished", 1)
    initRouter([]);
  })
}
// getRouterList();

function initRouter(paths = []) {
  let pathname = location.pathname;
  let fullPath = location.pathname + location.search;

  if (whiteList.indexOf(pathname) !== -1) {
    return;
  }

  if (paths.indexOf(pathname) !== -1) {
    router.replace(fullPath)
  } else {
    router.replace("/401")
  }
}


export default getRouterList;